<template>
  <div class="container">
    <audit-header :data="auditData" />

    <van-list
      class="list"
      v-model="loading"
      :finished="finished"
      finished-text="-------- 已加载全部 --------"
      @load="onLoad"
    >
      <div
        class="list-item"
        v-for="(item, index) in auditData.userTaskAuditResultList"
        :key="index"
        :title="item"
        @click="goDetail(item.reportId)"
      >
        <div class="top">
          <div class="title">{{ item.taskProjectName }}：{{ item.taskSubTitle }}</div>
          <div class="status">审核未通过</div>
        </div>
        <div class="info-item">{{ item.reportDate }}</div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { List } from 'vant'
import AuditHeader from './components/AuditHeader.vue'
import { listUserTaskAuditResultReport } from '../../api/auditResult'
export default {
  components: { AuditHeader, [List.name]: List },
  data() {
    return {
      auditData: [],
      loading: false,
      finished: true,
      queryData: {}
    }
  },

  mounted() {
    // console.log('query', this.$route.query)
    const { userCode, terminalCode, taskProjectCode, reportDate } = this.$route.query

    this.queryData = { userCode, terminalCode, taskProjectCode, reportDate }

    document.title = '任务审核明细'
    this.onLoad()
  },

  methods: {
    onLoad() {
      this.loading = true
      listUserTaskAuditResultReport(this.queryData).then((res) => {
        this.auditData = res.data.data
      })
    },

    goDetail(reportId) {
      this.$router.push({
        path: '/auditResultDetail',
        query: { reportId }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@vv: 7.5vw;

.conatiner {
  min-height: 100vh;
  background: #f5f5f5;
}

.list {
  margin-top: 20 / @vv;
  .list-item {
    box-sizing: border-box;
    width: 100%;
    background: #fff;
    padding: 30 / @vv 30 / @vv 40 / @vv;
    line-height: 60 / @vv;
    color: #666;
    font-size: 32 / @vv;
    box-shadow: inset 0px -1px 0px 0px rgba(237, 237, 237, 1);
    .top {
      min-height: 40 / @vv;
      display: flex;
      align-items: center;
      margin-bottom: 30 / @vv;
      .title {
        color: #333;
        font-weight: 500;
        flex: 1;
      }
      .status {
        font-size: 24 / @vv;
        margin-left: 20 / @vv;
        color: #faad14;
      }
    }
  }
}
</style>
